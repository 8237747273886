import { ComputeBudgetProgram, Connection, Keypair, PublicKey, Transaction, TransactionInstruction, VersionedTransaction } from "@solana/web3.js";
import type { WalletAdapterProps } from "@solana/wallet-adapter-base";

export async function sendTx(environment: string, connection: Connection, payer: PublicKey, sendTransaction: WalletAdapterProps['sendTransaction'], ixs: TransactionInstruction[], priorityLevel: string, signers: Keypair[] = []) {
    let accountKeys: string[] = []
    ixs.forEach(ix => ix.keys.forEach(key => accountKeys.push(key.pubkey.toString())))

    const transaction = new Transaction()

    const priorityFeeIxs = await getPriorityFeeEstimate(connection, environment, accountKeys, priorityLevel)
    priorityFeeIxs.forEach(ix => transaction.add(ix))
    ixs.forEach(ix => transaction.add(ix))

    const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight }
    } = await connection.getLatestBlockhashAndContext();

    transaction.recentBlockhash = blockhash
    transaction.feePayer = payer

    if (signers.length > 0) {
        signers.forEach((signer) => transaction.partialSign(signer));
    }

    const signature = await sendTransaction(transaction, connection, { minContextSlot });

    await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });

    return signature
}

export async function sendVersionedTx(connection: Connection, tx: VersionedTransaction, sendTransaction: WalletAdapterProps['sendTransaction']) {
    const {
        value: { blockhash, lastValidBlockHeight }
    } = await connection.getLatestBlockhashAndContext();

    const signature = await sendTransaction(tx, connection);

    await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });

    return signature
}

export async function getPriorityFeeEstimate(connection: Connection, environment: string, accountKeys: string[], priorityLevel: string) {
    const ixs: TransactionInstruction[] = []

    // The endpoint does not exist for devnet. When testing on devnet we need to set the priority level to NONE
    if (environment !== "devnet") {
        const response = await fetch(connection.rpcEndpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                jsonrpc: "2.0",
                id: "1",
                method: "getPriorityFeeEstimate",
                params: [
                    {
                        accountKeys: accountKeys,
                        options: { priorityLevel: priorityLevel },
                    },
                ],
            }),
        });
        const data = await response.json();

        if (data.result.priorityFeeEstimate) {
            const computeUnitLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
                units: 250000
            });
            ixs.push(computeUnitLimitIx)

            const computeUnitPriceIx = ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: BigInt(Math.floor(data.result.priorityFeeEstimate))
            });
            ixs.push(computeUnitPriceIx)
        } else {
            const computeUnitLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
                units: 250000
            });
            ixs.push(computeUnitLimitIx)

            const computeUnitPriceIx = ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: BigInt(10000000)
            });
            ixs.push(computeUnitPriceIx)
        }
    }

    return ixs
}

export function convertJupiterIxs(ixs: any[]): TransactionInstruction[] {
    const convertedIxs: TransactionInstruction[] = []

    ixs.forEach(ix => {
        convertedIxs.push({
            programId: new PublicKey(ix.programId),
            data: Buffer.from(ix.data, "base64"),
            keys: ix.accounts?.map((account: any) => ({
                pubkey: new PublicKey(account.pubkey),
                isSigner: account.isSigner,
                isWritable: account.isWritable
            }))
        })
    })

    return convertedIxs
}