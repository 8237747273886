import { useState, useEffect } from 'react';

export const useJitoSOLPrice = () => {
    const [price, setPrice] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchPrice = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const pythResponse = await fetch(
                'https://hermes.pyth.network/v2/updates/price/latest?ids%5B%5D=0x67be9f519b95cf24338801051f9a808eff0a578ccb388db73b7f6fe1de019ffb'
            );
            const pythData = await pythResponse.json();
            const pythPriceData = pythData?.parsed?.[0]?.price?.price;
            const expo = pythData.parsed[0].price.expo;
            const pythPrice = pythPriceData ? pythPriceData * Math.pow(10, expo) : null;
            if (pythPrice) {
                setPrice(pythPrice);
            } else {
                throw new Error('Pyth price data not found');
            }
        } catch (err) {
            console.error('Error fetching JitoSOL price from Pyth:', err);
            setError('Error fetching JitoSOL Price');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPrice();
    }, []);

    return { price, isLoading, error };
};
