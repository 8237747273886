import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { PublicKey } from '@solana/web3.js';
import { LstBalance, LstAvailable } from '../rest/kairos/types';

export const useLstBalances = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [lstBalances, setLstBalances] = useState<LstBalance[] | null>(null);
    const [lstAvailable, setLstAvailable] = useState<LstAvailable | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchLstBalances = async (wallet: PublicKey | null) => {
        if (!wallet) {
            setLstBalances(null);
            setIsLoading(false);
            setError("Wallet not connected");
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/lst?wallet=${wallet.toBase58()}`);
            const data = await response.json();
            if (data) {
                const lstBalancesData: LstBalance[] = data.balances;
                setLstBalances(lstBalancesData);
            }
        } catch (err) {
            console.error("Error fetching LST balances:", err);
            setLstBalances(null);
            setError("Error fetching LST balances");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLstAvailable = async (wallet: PublicKey | null) => {
        if (!wallet) {
            setLstBalances(null);
            setIsLoading(false);
            setError("Wallet not connected");
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/lst/available?wallet=${wallet.toBase58()}`);
            const data = await response.json();
            if (data) {
                const lstAvailableData: LstAvailable = data;
                setLstAvailable(lstAvailableData);
            }
        } catch (err) {
            console.error("Error fetching LST available:", err);
            setLstAvailable(null);
            setError("Error fetching LST available");
        } finally {
            setIsLoading(false);
        }
    }

    return { lstBalances, lstAvailable, isLoading, error, fetchLstBalances, fetchLstAvailable };
};
