import { useState, useEffect, useContext } from 'react';
import { AppConfigContext } from '../context';
import { DeFiOpportunities } from '../rest/kairos/types';

export const useDeFiOpportunities = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [opportunities, setOpportunities] = useState<DeFiOpportunities[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchOpportunities = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/defi`);
            const data = await response.json();
            if (data) {
                setOpportunities(data);
            }
        } catch (err) {
            console.error("Error fetching opportunities:", err);
            setOpportunities([]);
            setError("Error fetching opportunities");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchOpportunities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { opportunities, isLoading, error };
};