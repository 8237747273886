import { useToast } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

export function useErrorToast() {
  const toast = useToast()
  function errorToast(message: string) {
    toast({
      render: () => (
        <Box
          color="white"
          p={3}
          bg="red.400"
          style={{ lineHeight: '1.5', borderRadius: '8px' }}
        >
          <strong>Alas!</strong>
          <div>{message}</div>
        </Box>
      ),
      duration: 5000,
      isClosable: true,
    })
  }

  return { errorToast }
}

export function useSuccessToast() {
  const toast = useToast()
  function successToast(message: string) {
    toast({
      render: () => (
        <Box
          color="white"
          p={3}
          bg="green.500"
          style={{ lineHeight: '1.5', borderRadius: '8px' }}
        >
          <strong>Huzzah!</strong>
          <div>{message}</div>
        </Box>
      ),
      duration: 5000,
      isClosable: true,
    })
  }
  return { successToast }
}