import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { LeaderboardResponse } from '../rest/kairos/types';

export const useLeaderboard = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [leaderboard, setLeaderboard] = useState<LeaderboardResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchLeaderboard = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/leaderboard`);
            const data = await response.json();
            if (data) {
                setLeaderboard(data);
            }
        } catch (err) {
            console.error("Error fetching Rank:", err);
            setLeaderboard(null);
            setError("Error fetching Leaderboard");
        } finally {
            setIsLoading(false);
        }
    };

    return { leaderboard, isLoading, error, fetchLeaderboard };
};