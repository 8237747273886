import { Flex, VStack, Image, Text, Box } from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";

interface CTAProps {
    destination: string
    destinationTracking: string
    image: string
    title: string
    description: string
}

export const CTA = ({ destination, destinationTracking, image, title, description }: CTAProps) => {
    return (
        <Box
          as="a"
          href={destination}
          target={destination.startsWith('http') ? '_blank' : undefined}
          rel={destination.startsWith('http') ? 'noopener noreferrer' : undefined}
          flex="1"
          p={4}
          borderRadius="8px"
          backgroundColor="whiteAlpha.100"
          height="100%"
          transition="all 0.2s"
          _hover={{
            backgroundColor: "whiteAlpha.200",
            transform: "translateY(-2px)",
            cursor: "pointer"
          }}
          onClick={() => {
            mixpanel.track("CTA", {
              source: "/",
              destination: destinationTracking
            });
          }}
        >
          <Flex alignItems="center" gap={4} height="100%">
            <Image src={image} boxSize={{ base: "30px", md: "40px" }} borderRadius="full" />
            <VStack align="flex-start" spacing={1}>
              <Text fontSize={{ base: "md", md: "lg" }} fontWeight="semibold" color="white">
                {title}
              </Text>
              <Text fontSize="sm" fontWeight="light" color="white">
                {description}
              </Text>
            </VStack>
          </Flex>
        </Box>
    )
}