import { Badge, Container, Flex, Text, VStack } from "@chakra-ui/react";
import { DeFiTable } from "../components/DeFiTable";
import { useDeFiOpportunities } from "../hooks/useDeFiOpportunities";
import { useErrorToast } from "../helpers/toast";
import { useEffect } from "react";
import { usePoints } from "../hooks/usePoints";
import { useWallet } from "@solana/wallet-adapter-react";
import { Warchest } from "../components/Warchest";

export const DeFiPage = () => {
  const { publicKey } = useWallet();
  const { opportunities, isLoading, error } = useDeFiOpportunities();
  const { points, fetchPoints } = usePoints();
  const { errorToast } = useErrorToast();

  useEffect(() => {
    if (error) {
      errorToast(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    fetchPoints(publicKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container maxW="900px" flex="1" py={8}>
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Earn on kySOL & kyJTO
              </Text>
                <Badge
                  colorScheme="green"
                  borderRadius="full"
                  px={4}
                  py={1}
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  height="auto"
                >
                  ≈ 1.25-10x points
                </Badge>
            </Flex>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              Provide your kySOL and kyJTO to DeFi protocols to earn bonus points for your warchest.
            </Text>

            {points && <Warchest points={points} />}

            <DeFiTable opportunities={opportunities} isLoading={isLoading} points={points} />
          </VStack>
        </Container>
      </Flex>
    </>
  );
};
