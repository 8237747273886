import { Text, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface Props {
    currency: string;
    price: number | null;
    totalSupply: string | null;
    isTotalSupplyLoading: boolean;
    totalSupplyError: string | null;
}

export const UncappedTvl = ({ currency, price, totalSupply, isTotalSupplyLoading, totalSupplyError }: Props) => {
    const isLoading = isTotalSupplyLoading;
    const error = totalSupplyError;

    const depositedSOL = parseFloat(totalSupply ?? '') || 0;

    const [usdcValue, setUsdcValue] = useState<number | null>(null);

    useEffect(() => {
        if (price) {
            setUsdcValue(depositedSOL * price);
        }
    }, [price, depositedSOL]);

    // Function to format numbers in K and M
    const formatNumber = (number: number) => {
        const absNumber = Math.abs(number);
        if (absNumber >= 1e6) {
            return (number / 1e6).toFixed(2).replace(/\.00$/, '') + 'M';
        } else if (absNumber >= 1e3) {
            return (number / 1e3).toFixed(2).replace(/\.00$/, '') + 'K';
        } else {
            return number.toFixed(2);
        }
    };

    return (
        <div>
            <Heading as="h2" fontSize="xl" color="white" fontWeight="bold" mb={4}>
                TVL
            </Heading>
            {isLoading ? (
                <Text color="white">Loading...</Text>
            ) : error ? (
                <Text color="red.500">{error}</Text>
            ) : (
                <>
                    <Text fontSize="xl" color="white" fontWeight="light">
                        {formatNumber(depositedSOL)} {currency} {usdcValue && <> ≈ <Text as="span" fontWeight="semibold">${formatNumber(usdcValue)}</Text></>}
                    </Text>
                </>
            )}
        </div>
    );
};
