import { useState } from 'react';
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { AnchorProvider, Idl, Program, setProvider } from "@coral-xyz/anchor";
import * as jitoIdl from "../idl/jito_vault_v3.json";
import { WithdrawalTicket } from "../helpers/dataStructures";
import { fetchUnstakeTime } from "../helpers/epoch";


export const useWithdrawalTickets = () => {
  const { connection } = useConnection();
  const wallet = useAnchorWallet()

  const [withdrawalTickets, setWithdrawalTickets] = useState<WithdrawalTicket[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchWithdrawalTickets = async (vaultAddress: string, publicKey: PublicKey | null) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!publicKey || !wallet) {
        setError("Please connect your wallet.")
        return
      };
      const provider = new AnchorProvider(connection, wallet, {});
      setProvider(provider);
      const program = new Program(jitoIdl as Idl, { connection });

      // @ts-ignore
      const withdrawalTicketData = await program.account.vaultStakerWithdrawalTicket.all();

      const matchingTickets = await Promise.all(
        withdrawalTicketData
          .filter((ticket: any) => (ticket.account.staker.toBase58() === publicKey?.toBase58() && ticket.account.vault.toBase58() === vaultAddress))
          .map(async (ticket: any) => ({
            base: ticket.account.base.toBase58(),
            publicKey: ticket.publicKey.toBase58(),
            slotUnstaked: ticket.account.slotUnstaked.toNumber(),
            staker: ticket.account.staker.toBase58(),
            vault: ticket.account.vault.toBase58(),
            vrtAmount: ticket.account.vrtAmount.toNumber() / LAMPORTS_PER_SOL,
            ...await fetchUnstakeTime(connection, ticket.account.slotUnstaked.toNumber())
          }))
      );
      matchingTickets.sort((a: WithdrawalTicket, b: WithdrawalTicket) => a.slotUnstaked - b.slotUnstaked);

      setWithdrawalTickets(matchingTickets)
    } catch (error) {
      setError("Error fetching withdrawal ticket.")
    } finally {
      setIsLoading(false);
    }
  };

  return { withdrawalTickets, isLoading, error, fetchWithdrawalTickets };
};
