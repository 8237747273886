import { useState, useCallback, useContext } from "react";
import {
    Keypair,
    PublicKey,
    SystemProgram,
    TransactionInstruction
} from "@solana/web3.js";
import {
    getAssociatedTokenAddress,
    createAssociatedTokenAccountIdempotentInstruction,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { AppConfigContext } from "../context";
import { createEnqueueWithdrawalPayload } from "../helpers/payloads";
import { sendTx } from "./utils/utils";
import mixpanel from "mixpanel-browser";

export const useEnqueueWithdrawal = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const wallet = useAnchorWallet()
    const { environment, priorityFeeLevel, vaultProgramAddress, vaultConfigAddress } = useContext(AppConfigContext)

    const vaultConfig = new PublicKey(vaultConfigAddress)
    const vaultProgram = new PublicKey(vaultProgramAddress)
    const staker = publicKey

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const enqueueWithdrawal = useCallback(async (vrtMintAddress: string, vaultAddress: string, amount: string) => {
        if (!staker) {
            setError("Please connect your wallet.")
            return false
        }

        if (amount === '' || Number(amount) === 0) {
            setError("Please enter an amount greater than 0.")
            return false
        }

        if (!wallet) {
            setError("Please connect your wallet.")
            return false
        }

        const vrtMint = new PublicKey(vrtMintAddress)
        const vault = new PublicKey(vaultAddress)
        mixpanel.track('Enqueue Withdrawal', {
            'amount': amount,
            'wallet': wallet.publicKey?.toBase58(),
        })

        setLoading(true);

        let ixs: TransactionInstruction[] = []
        const amountInLamports = Math.round(Number(amount) * LAMPORTS_PER_SOL);
        const base = Keypair.generate();

        try {
            const stakerVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                staker
            );

            // 1. PDA for ticket account
            const seeds = [
                Buffer.from("vault_staker_withdrawal_ticket"),
                vault.toBuffer(),
                base.publicKey.toBuffer(),
            ];
            const [ticket] = PublicKey.findProgramAddressSync(seeds, vaultProgram);

            // 2. ATA for VRT / ticket
            const ticketVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                ticket,
                true
            );

            const createIdempotentTicketVrtAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                ticketVrtAta,
                ticket,
                vrtMint
            );
            ixs.push(createIdempotentTicketVrtAtaIx);

            // 3. Enqueue withdrawal
            const enqueueWithdrawalIx = new TransactionInstruction({
                keys: [
                    { pubkey: vaultConfig, isSigner: false, isWritable: false },  // Config account
                    { pubkey: vault, isSigner: false, isWritable: true },    // Vault account
                    { pubkey: ticket, isSigner: false, isWritable: true },  // Vault staker withdrawal ticket account
                    { pubkey: ticketVrtAta, isSigner: false, isWritable: true }, // Vault staker withdrawal ticket token account
                    { pubkey: staker, isSigner: true, isWritable: true }, // Staker account
                    { pubkey: stakerVrtAta, isSigner: false, isWritable: true }, // Staker VRT token account
                    { pubkey: base.publicKey, isSigner: true, isWritable: true }, // Staker base account
                    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // Token program
                    { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }, // System program
                    { pubkey: vaultProgram, isSigner: false, isWritable: true }  // Burn signer
                ],
                programId: vaultProgram,
                data: createEnqueueWithdrawalPayload(amountInLamports)
            });
            ixs.push(enqueueWithdrawalIx);

            return await sendTx(environment, connection, staker, sendTransaction, ixs, priorityFeeLevel, [base])
        } catch (err) {
            setError(`Something went wrong with the transaction: ${err}`)
            return false
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staker, wallet]);

    return { enqueueWithdrawal, loading, error };
};
