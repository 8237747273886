import { useState, useEffect, useContext } from 'react';
import { AppConfigContext } from '../context';
import { Apy } from '../rest/kairos/types';

export const useKyrosApy = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [apy, setApy] = useState<Apy | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchApy = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/apy`);
            const data: Apy = await response.json();
            if (data) {
                setApy(data);
            }
        } catch (err) {
            console.error("Error fetching APY:", err);
            setApy(null);
            setError("Error fetching APY");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchApy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { apy, isLoading, error };
};
