import { useCallback, useContext, useState } from "react";
import { PublicKey, TransactionInstruction, TransactionMessage, VersionedTransaction, AddressLookupTableAccount } from "@solana/web3.js";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { AppConfigContext } from "../context";
import { convertJupiterIxs, sendVersionedTx } from "./utils/utils";
import mixpanel from "mixpanel-browser";

export const useConvert = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const wallet = useAnchorWallet()
    const { priorityFeeLevel } = useContext(AppConfigContext)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const convert = useCallback(async (quoteResponse: any) => {
        if (!wallet || !publicKey) {
            setError("Please connect your wallet.")
            return null
        }

        mixpanel.track('Convert LST', {
            'mint': quoteResponse.inputMint.address,
            'amount': quoteResponse.inputAmount,
            'wallet': wallet.publicKey?.toBase58(),
        })

        setLoading(true);

        try {
            let ixs: TransactionInstruction[] = []

            // Query ixs from Jupiter
            const body = {
                userPublicKey: publicKey,
                dynamicComputeUnitLimit: true,
                allowOptimizedWrappedSolTokenAccount: true,
                priorityLevelWithMaxLamports: {
                    priorityFeeLevel: priorityFeeLevel.toLowerCase(),
                    maxLamports: 10000000
                },
                quoteResponse: quoteResponse
            }

            const response = await fetch(`https://quote-api.jup.ag/v6/swap-instructions`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json()

            ixs.push(...convertJupiterIxs(data.computeBudgetInstructions))
            ixs.push(...convertJupiterIxs(data.setupInstructions))
            ixs.push(...convertJupiterIxs([data.swapInstruction]))

            if (data.cleanupInstruction) {
                ixs.push(...convertJupiterIxs([data.cleanupInstruction]))
            }

            ixs.push(...convertJupiterIxs(data.otherInstructions))
            
            // Add address table lookups from Jupiter response
            let addressLookupTableAccounts: AddressLookupTableAccount[] = []

            if (data.addressLookupTableAddresses) {
                addressLookupTableAccounts = await Promise.all(
                    data.addressLookupTableAddresses.map(async (lookupTableAddress: string) => {
                        const lookupTableKey = new PublicKey(lookupTableAddress)
                        const lookupTableAccount = await connection.getAddressLookupTable(lookupTableKey);
                        if (!lookupTableAccount.value) {
                            throw new Error(`Lookup table not found: ${lookupTableKey.toBase58()}`);
                        }
                        return lookupTableAccount.value;
                    })
                );
            }

            const message = new TransactionMessage({
                payerKey: new PublicKey(publicKey),
                recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
                instructions: ixs,
            }).compileToV0Message(addressLookupTableAccounts)

            const transaction = new VersionedTransaction(message);
            return await sendVersionedTx(connection, transaction, sendTransaction)
        } catch (error) {
            setError(`Something went wrong with the transaction: ${error}`)
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, wallet]);

    return { convert, loading, error };
};
