import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  VStack,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Image,
  HStack,
  Link,
} from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useFormik } from "formik";
import { useEffect, useContext, useState } from "react";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import { AppConfigContext } from "../context";
import { useBalance } from "../hooks/useBalance";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useMintTo } from "../hooks/useMintTo";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useKyrosApy } from "../hooks/useKyrosApy";
import { CTA, FAQ, UncappedTvl } from "../components";
import "../styles/MintBox.css";
import { solAmountFormatter } from "../helpers/formatter";
import { useTotalSupply } from "../hooks/useTotalSupply";
import { useJtoPrice } from "../hooks/useJtoPrice";

export const RestakeJtoPage = () => {
  const { apy: kyrosApy } = useKyrosApy();
  const { price } = useJtoPrice();
  const { publicKey } = useWallet();
  const { jtoMintAddress, jtoVrtMintAddress, jtoVaultAddress } = useContext(AppConfigContext);

  const [displayedBalance, setDisplayedBalance] = useState<string>("0 JTO");
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const { balance: jtoBalance, fetchBalance: fetchJtoBalance } = useBalance(new PublicKey(jtoMintAddress));
  const { totalSupply, isLoading: isTotalSupplyLoading, error: totalSupplyError, fetchTotalSupply } = useTotalSupply();
  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();
  const { mintTo, error: mintToError } = useMintTo();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    formik.setValues({
      amount: inputValue,
      receivedAmount: inputValue
    });
  };

  const handleMaxClick = () => {
    const maxBalance = jtoBalance?.toString() || "0"

    formik.setValues({
      amount: solAmountFormatter.format(Number(maxBalance)).replace(/,/g, ""),
      receivedAmount: solAmountFormatter.format(Number(maxBalance)).replace(/,/g, "")
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      receivedAmount: "",
    },
    onSubmit: async (values) => {
      try {
        const signature = await mintTo(jtoVrtMintAddress, jtoMintAddress, jtoVaultAddress, values.amount, undefined);
        if (signature) {
          successToast("Your mint was successful!");
          reload();
        }
      } catch (err) {
        errorToast(`${err}`);
      }
    },
  });

  useEffect(() => {
    if (mintToError) {
      errorToast(mintToError);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintToError]);

  const reload = () => {
    formik.resetForm();
    fetchJtoBalance(publicKey);
    fetchTotalSupply(jtoVrtMintAddress);
  };

  useEffect(() => {
    setFormDisabled((Number(formik.values.amount) > Number(jtoBalance?.toString() || Number("0"))) || formik.values.amount === "0" || formik.values.amount === "")
  }, [formik.values.amount, jtoBalance]);

  useEffect(() => {
    fetchJtoBalance(publicKey);
    fetchTotalSupply(jtoVrtMintAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    setDisplayedBalance(solAmountFormatter.format(Number(jtoBalance?.toString())) + " JTO");
  }, [jtoBalance]);

  const { setVisible } = useWalletModal();
  const { connecting } = useWallet();

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW="520px"
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Get kyJTO
              </Text>
              {kyrosApy !== null && (
                <Badge
                  colorScheme="green"
                  borderRadius="full"
                  px={4}
                  py={1}
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  height="auto"
                >
                  {/* ≈ {(kyrosApy.jto * 100).toFixed(2)}% APY */}
                  PRE-LAUNCH
                </Badge>
              )}
            </Flex>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              Earn yield on your Jito (JTO) tokens by restaking them on Jito TipRouter NCN.
            </Text>

            <UncappedTvl currency="JTO" price={price} totalSupply={totalSupply} isTotalSupplyLoading={isTotalSupplyLoading} totalSupplyError={totalSupplyError} />

            <Flex justifyContent="space-between" alignItems="stretch" gap={4}>
              <CTA
                destination="https://app.kamino.finance/liquidity/CMgoBugT4yFa18gL9aMev4CRpZyFDrv1AkfvgHPadWBN"
                destinationTracking="kamino"
                image="/kyjto.svg"
                title={"Earn more on your kyJTO!"}
                description="Provide kyJTO/JTO LP on Kamino, earn more yield and 7.5x Warchest points!"
              />
            </Flex>

            <Box className="mint-box">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={4}
              >
                <Text fontSize="md" fontWeight="semibold" color="white">
                  (RE)STAKE
                </Text>
                {publicKey && (
                  <HStack spacing={2} alignItems="center">
                    <Image src="/wallet-icon.svg" boxSize="16px" />
                    <Text fontSize="sm" fontWeight="light" color="white">
                      {displayedBalance}
                    </Text>
                    <Button
                      size="sm"
                      onClick={handleMaxClick}
                      variant="ghost"
                      color="whiteAlpha.800"
                      _hover={{ bg: "whiteAlpha.200" }}
                      backgroundColor="whiteAlpha.300"
                      fontWeight="normal"
                      paddingX={2}
                    >
                      MAX
                    </Button>
                  </HStack>
                )}
              </Flex>

              <form onSubmit={formik.handleSubmit}>
                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >

                  <InputLeftAddon
                    p={0}
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "xl",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      _hover: { color: "white.300" },
                      width: "150px",        // Fixed width
                    }}
                  >
                    <Box display="flex" alignItems="center" pl={3}>
                      <Image
                        src="/jto.svg"
                        boxSize="30px"
                        ml={2}
                        borderRadius="full"
                        border="2px solid #222F44"
                      />
                      <Text fontWeight="bold" ml={2} color="white">JTO</Text>
                    </Box>
                  </InputLeftAddon>

                  <Input
                    id="amount"
                    placeholder="0.0"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    color="white"
                    sx={{
                      width: "100%",
                      height: "60px",
                      fontSize: "md",
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",
                      _placeholder: { color: "#595959" },
                    }}
                  />

                </InputGroup>

                <Flex justifyContent="center" alignItems="center">
                  <ArrowDownIcon color="grey" boxSize={6} />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    TO RECEIVE
                  </Text>
                  <Text fontSize="xs" fontWeight="light" color="#9A9998">
                    0% Slippage
                  </Text>
                </Flex>

                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >
                  <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/kyjto.svg"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">kyJTO</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  />
                  <Input
                    id="receive"
                    placeholder="0.0"
                    value={formik.values.receivedAmount}
                    readOnly
                    color="white"
                    sx={{
                      width: "100%",        // Makes the input take up full width within InputGroup
                      height: "60px",       // Set height to match addon
                      fontSize: "md",       // Set font size to match addon
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",     // Aligns the text to the right
                      _placeholder: { color: "595959" },
                    }}
                  />
                </InputGroup>

                <Box
                  flex="1"
                  p={4}
                  borderRadius="8px"
                  backgroundColor="whiteAlpha.100"
                  height="100%"
                  mb={4}
                >
                  <Flex alignItems="center" gap={4} height="100%">
                    <Image src={"/kyros/warchest.png"} boxSize={{ base: "30px", md: "40px" }} borderRadius="full" />
                    <VStack align="flex-start" spacing={1}>
                      <Text fontSize={{ base: "md", md: "lg" }} fontWeight="semibold" color="white">
                        5x Warchest Points
                      </Text>
                      {Number(formik.values.amount) > 0 && (
                        <Text fontSize="sm" fontWeight="light" color="white">
                          Your position will count as <b>{solAmountFormatter.format(Number(formik.values.amount) * 5)} kyJTO</b> !
                        </Text>
                      )}
                    </VStack>
                  </Flex>
                </Box>

                {!publicKey ? (
                  <Flex justifyContent="center" width="100%">
                    <Button
                      width="100%"
                      className="mint-box-button"
                      isDisabled={connecting}
                      onClick={() => setVisible(true)}
                    >
                      {connecting ? "Connecting..." : "Connect Wallet"}
                    </Button>
                  </Flex>
                ) : (
                  <Flex justifyContent="center" width="100%">
                    <Button width="100%" className="mint-box-button" isDisabled={formDisabled} isLoading={formik.isSubmitting} type="submit">
                      Convert to kyJTO
                    </Button>
                  </Flex>
                )}
              </form>

              <Flex className="exchange-info" mt={4}>
                <Text>Exchange rate:</Text>
                <Text ml={2}>1 JTO = 1 kyJTO</Text>
              </Flex>
            </Box>

            <FAQ questions={[
              { question: "What is Kyros?", answer: <Text>Kyros is a liquid restaking protocol built on Jito (Re)staking, offering uncapped yield through kySOL, a yield-bearing token on Solana that combines staking rewards, MEV rewards, and restaking rewards into a single token.</Text> },
              { question: "What is kySOL?", answer: <Text>kySOL represents your deposit of JitoSOL in our VRT vault, which supports the security of Node Consensus Networks (NCNs) while providing additional rewards. With kySOL, you earn Solana staking rewards, MEV rewards, and restaking rewards—all in a single token.</Text> },
              { question: "How to (Re)stake?", answer: <Text>Restaking is as simple as minting kySOL. When you deposit your staked assets into our vault, we delegate them to industry-leading node operators who validate transactions for NCNs. Our chosen node operators have extensive experience and a strong track record in network security and efficiency.</Text> },
              { question: "How to (Un)stake?", answer: <Text>You can unstake through the website, with a waiting period of at least one epoch (~2 days) to receive your JitoSOL back. Alternatively, you can swap kySOL for JitoSOL instantly on <Link color='#5EF4F3' href='https://jup.ag/swap/kySo1nETpsZE2NWe5vj2C64mPSciH1SppmHb4XieQ7B-JitoSOL' isExternal>Jupiter</Link>.</Text> },
              { question: "Who are the NCNs to which you delegate?", answer: <Text>Kyros will delegate to the Jito TipRouter NCN upon its launch and plans to integrate additional NCNs later through a dedicated process combining on-chain governance and risk curation.</Text> },
              { question: "Who are the node operators?", answer: <Text>Initially, Kyros will partner with Kiln, Luganodes, Helius, Temporal and Laine as our node operators. You can learn more about them in our <Link color='#5EF4F3' href='https://docs.kyros.fi/' isExternal>documentation</Link>.</Text> },
            ]} />

          </VStack >
        </Container >
      </Flex >
    </>
  );
};
