import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  VStack,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Image,
  HStack,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  ButtonGroup,
  Spinner,
} from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useFormik } from "formik";
import { useEffect, useContext, useState } from "react";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import { AppConfigContext } from "../context";
import { ArrowDownIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useKyrosApy } from "../hooks/useKyrosApy";
import "../styles/MintBox.css";
import { useLstBalances } from "../hooks/useLstBalances";
import { LstBalance } from "../rest/kairos/types";
import { useConvert } from "../hooks/useConvert";

export const LstPage = () => {
  const { apy: kyrosApy } = useKyrosApy();
  const { lstBalances, isLoading: isLstBalancesLoading, fetchLstBalances } = useLstBalances();

  const { publicKey } = useWallet();
  const { solVrtMintAddress, solVaultPaused } = useContext(AppConfigContext);

  const slippageOptions = [0.1, 0.3, 1]

  const [selectedMint, setSelectedMint] = useState<LstBalance | null>(null);
  const [exchangeRate, setExchangeRate] = useState<number | null>(null);
  const [priceImpact, setPriceImpact] = useState<string | null>(null);
  const [quoteResponse, setQuoteResponse] = useState<any>(null);
  const [slippageBps, setSlippageBps] = useState<number>(0.3);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();
  const { convert, error: convertError } = useConvert();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    let receivedValue = inputValue;
    if (exchangeRate) {
      receivedValue = inputValue ? `≈ ${(Number(inputValue) * exchangeRate).toFixed(9)}` : "";
    } else {
      receivedValue = "...";
    }

    formik.setValues({
      amount: inputValue,
      receivedAmount: receivedValue
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      receivedAmount: "",
    },
    onSubmit: async (values) => {
      if (!quoteResponse) {
        return;
      }

      try {
        const signature = await convert(quoteResponse);
        if (signature) {
          successToast("Your conversion was successful!");
          reload();
        }
      } catch (err) {
        errorToast(`${err}`);
      }
    },
  });

  useEffect(() => {
    if (convertError) {
      errorToast(convertError);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertError]);

  const reload = () => {
    formik.resetForm();
    fetchLstBalances(publicKey);
  };

  useEffect(() => {
    if (!lstBalances || !selectedMint) {
      return;
    }

    const balance = lstBalances.find((balance: LstBalance) => balance.metadata?.mint === selectedMint.metadata?.mint)?.balance.toFixed(selectedMint.metadata?.decimals) || "0"

    setFormDisabled(formik.values.amount > balance);
  }, [formik.values.amount, lstBalances, selectedMint]);

  useEffect(() => {
    fetchLstBalances(publicKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    if (lstBalances && lstBalances.length > 0) {
      setSelectedMint(lstBalances[0]);
    }
  }, [lstBalances]);

  useEffect(() => {
    const fetchRate = async () => {
      if (!selectedMint || !selectedMint.metadata) {
        return;
      }

      const rate = await fetch(`https://quote-api.jup.ag/v6/quote?inputMint=${selectedMint.metadata?.mint}&outputMint=${solVrtMintAddress}&amount=${selectedMint.balance * 1e9}&slippageBps=${slippageBps * 100}&asLegacyTransaction=false`)
      const response = await rate.json();

      formik.setValues({
        amount: selectedMint.balance.toString(),
        receivedAmount: (response.outAmount / 1e9).toFixed(selectedMint.metadata?.decimals)
      });

      setExchangeRate(response.outAmount / response.inAmount);
      setPriceImpact((response.priceImpactPct * 100).toFixed(2) + "% Price Impact");
      setQuoteResponse(response);
    }
    fetchRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMint, slippageBps, solVrtMintAddress]);

  const { setVisible } = useWalletModal();
  const { connecting } = useWallet();

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW="520px"
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={8} align="stretch">
            <VStack spacing={4} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Earn more rewards with kySOL
              </Text>
              {kyrosApy !== null && (
                <Badge
                  colorScheme="green"
                  borderRadius="full"
                  px={4}
                  py={1}
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  height="auto"
                >
                  ≈ {(kyrosApy.sol * 100).toFixed(2)}% APY
                </Badge>
              )}
            </Flex>
            </VStack>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              One-click convert your LSTs to kySOL to get a boosted APY generated from staking, MEV, and restaking rewards.
            </Text>

            { (connecting || !publicKey) && (
              <Box className="mint-box" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
                <Text align="center">Please connect to your wallet to see your balances.</Text>
              </Box>
            )}

            { isLstBalancesLoading && (
              <Box className="mint-box" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
                <Spinner />
                <Text align="center">Loading your LSTs, please be a little bit more patient dear adventurer.</Text>
              </Box>
            )}

            { !isLstBalancesLoading && !selectedMint && lstBalances && lstBalances.length === 0 && (
              <Box className="mint-box" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
                <Text align="center">You don't have any LSTs to convert to kySOL.</Text>
              </Box>
            )}


            {publicKey && !isLstBalancesLoading && selectedMint && (
                <Box className="mint-box">
                  <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb={4}
                  mt={4}
                >
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    CONVERT
                  </Text>
                </Flex>

                <form onSubmit={formik.handleSubmit}>
                  <InputGroup
                    mb={4}
                    sx={{
                      borderRadius: "8px",
                      borderColor: "gray.500",
                    }}
                  >

                    <InputLeftAddon
                      p={0}
                      sx={{
                        color: "white.200",
                        paddingX: "12px",      // Match padding with input box
                        fontSize: "xl",        // Match font size
                        height: "60px",        // Match height with input box
                        display: "flex",
                        alignItems: "center",  // Center the text vertically
                        borderWidth: "0.1px",
                        borderColor: "#403D3B",
                        _hover: { color: "white.300" },
                        width: "150px",        // Fixed width
                      }}
                    >
                      <Menu placement="bottom">
                        <MenuButton
                          as={Button}
                          variant="unstyled" // Remove theme styling
                          borderRadius={0}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center", // Center content horizontally
                            fontWeight: "bold",
                            color: "white",
                            padding: 0,        // Remove extra padding
                            height: "60px",    // Match the height
                            zIndex: 1,         // Ensure the button is above other elements
                            width: "100%",     // Occupy full width of the parent (130px)
                            role: "group" // Enables group hover
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            width="100%"              // Occupy full width
                            justifyContent="center"   // Center content horizontally
                          >
                            <Image
                              src={selectedMint?.metadata?.imageUri ?? "/unknown.svg"}
                              boxSize="25px"
                              borderRadius="full"
                            />
                            <Text fontWeight="bold" ml={1} color="white">
                              {selectedMint?.metadata?.symbol ?? "..."}
                            </Text>
                            <ChevronDownIcon
                              ml={1}
                              color="white"
                              transition="all 0.3s ease" // Adds smooth transition
                              _groupHover={{
                                color: "#5DF0EE", // Example color change
                              }}
                            />
                          </Box>
                        </MenuButton>
                        <Portal>
                          <MenuList
                            zIndex={1000} // Set a high zIndex to ensure visibility
                            left="50%"    // Center horizontally
                            transform="translateX(-50%)"
                            backgroundColor="#18181b"
                          >
                            {lstBalances && lstBalances.slice(0, 10).map((balance: LstBalance) => (
                                <MenuItem
                                  key={balance.metadata?.mint ?? balance.pubkey}
                                  onClick={() => {
                                    setSelectedMint(balance);
                                  }}
                                  margin={0}
                                  backgroundColor="#18181b"
                                >
                                  <Box display="flex" alignItems="center">
                                    <VStack spacing={0} align="flex-start">
                                      <HStack spacing={1}>
                                        <Image src={balance.metadata?.imageUri ?? "/unknown.svg"} boxSize="24px" mr="2" />
                                        <Text color="white">
                                          {balance.metadata?.name ?? "..."}
                                        </Text>
                                      </HStack>
                                      <HStack spacing={1}>
                                        <Box display="flex" alignItems="center" boxSize="32px" />
                                        <Text fontSize="xs" color="#9A9998">
                                          {(balance.balance).toFixed(2)} (≈ ${balance.value.toLocaleString(undefined, { maximumFractionDigits: 0 })})
                                        </Text>
                                      </HStack>
                                    </VStack>
                                  </Box>
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        </Portal>
                      </Menu>
                    </InputLeftAddon>

                    <Input
                      id="amount"
                      type="number"
                      placeholder="0.0"
                      value={formik.values.amount}
                      onChange={handleInputChange}
                      color="white"
                      readOnly
                      sx={{
                        width: "100%",
                        height: "60px",
                        fontSize: "md",
                        borderColor: "#403D3B",
                        borderWidth: "0.1px",
                        textAlign: "right",
                        _placeholder: { color: "#595959" },
                      }}
                    />

                  </InputGroup>

                  <Flex justifyContent="center" alignItems="center">
                    <ArrowDownIcon color="grey" boxSize={6} />
                  </Flex>

                  <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Text fontSize="md" fontWeight="semibold" color="white">
                      TO RECEIVE
                    </Text>
                    {priceImpact ? (
                      <Text fontSize="xs" fontWeight="light" color="#9A9998">
                        {priceImpact}
                      </Text>
                    ) : (
                      <Text fontSize="xs" fontWeight="light" color="#9A9998">
                        ...
                      </Text>
                    )}
                  </Flex>

                  <InputGroup
                    mb={4}
                    sx={{
                      borderRadius: "8px",
                      borderColor: "gray.500",
                    }}
                  >
                    <InputLeftAddon
                      children={
                        <Box display="flex" alignItems="center">
                          <Image
                            src="/kySOLLogo.svg"
                            boxSize="30px"
                            ml={2}
                            borderRadius="full"
                            border="2px solid #222F44"
                          />
                          <Text fontWeight="bold" ml={2} color="white">kySOL</Text>
                        </Box>
                      }
                      sx={{
                        color: "white.200",
                        paddingX: "12px",      // Match padding with input box
                        fontSize: "md",        // Match font size
                        height: "60px",        // Match height with input box
                        display: "flex",
                        alignItems: "center",  // Center the text vertically
                        borderWidth: "0.1px",
                        borderColor: "#403D3B",
                        width: "150px",
                      }}
                    />
                    <Input
                      id="receive"
                      placeholder="0.0"
                      value={formik.values.receivedAmount}
                      readOnly
                      color="white"
                      sx={{
                        width: "100%",        // Makes the input take up full width within InputGroup
                        height: "60px",       // Set height to match addon
                        fontSize: "md",       // Set font size to match addon
                        borderColor: "#403D3B",
                        borderWidth: "0.1px",
                        textAlign: "right",     // Aligns the text to the right
                        _placeholder: { color: "595959" },
                      }}
                    />
                  </InputGroup>


                  {!publicKey ? (
                    <Flex justifyContent="center" width="100%">
                      <Button
                        width="100%"
                        className="mint-box-button"
                        isDisabled={connecting}
                        onClick={() => setVisible(true)}
                      >
                        {connecting ? "Connecting..." : "Connect Wallet"}
                      </Button>
                    </Flex>
                  ) : (
                    <Flex justifyContent="center" width="100%">
                      <Button width="100%" className="mint-box-button" isDisabled={formDisabled || solVaultPaused} isLoading={formik.isSubmitting} type="submit">
                        Swap to kySOL
                      </Button>
                    </Flex>
                  )}
                </form>

                <Flex className="slippage" justifyContent="space-between" alignItems="center" alignContent="center" mt={8}>
                  <>
                    <Text my="auto">Slippage:</Text>
                    <ButtonGroup isAttached my="auto">
                      {slippageOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => setSlippageBps(option)}
                          bg={slippageBps === option ? "whiteAlpha.300" : "whiteAlpha.100"}
                          color={"white"}
                          transition="background-color 0.2s ease"
                          _hover={{ bg: slippageBps === option ? "whiteAlpha.300" : "whiteAlpha.200" }}
                        >
                          {option}%
                        </Button>
                      ))}
                    </ButtonGroup>
                  </>
                </Flex>

                <Flex className="exchange-info" mt={8}>
                  <Text>Exchange rate:</Text>
                  {exchangeRate ? (
                    <>
                      <Text ml={2}>1 {selectedMint?.metadata?.symbol ?? "..."} = {exchangeRate.toFixed(4)} kySOL</Text>
                    </>
                  ) : (
                    <Text ml={2}>...</Text>
                  )}
                </Flex>
              </Box>
            )}

          </VStack >
        </Container >
      </Flex >
    </>
  );
};
