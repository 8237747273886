import { Flex, Text } from "@chakra-ui/react";
import { ThemeColor } from "../../theme/palette";
import { getAlphaColor } from "../../theme/utils";
import { ReactNode, useEffect, useState } from "react";

export const Notice = () => {
    const [text, setText] = useState<ReactNode | null>(null);

    useEffect(() => {
        setText(<Text textAlign={"center"}>Introducing kyJTO: <a href="https://app.kyros.fi/jto" target="_blank" rel="noopener noreferrer">Restake</a> your <a href="https://app.kyros.fi/jto" target="_blank" rel="noopener noreferrer">Jito (JTO)</a> tokens and earn <a href="https://app.kyros.fi/defi" target="_blank" rel="noopener noreferrer">5x Warchest</a> points!</Text>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Flex
            color={ThemeColor.KAIROS}
            bg={getAlphaColor(ThemeColor.KAIROS as string, 0.1)}
            px={"1rem"}
            py={"1.5rem"}
            alignItems={"center"}
            textAlign={"center"}
            sx={{
                a: {
                    color: getAlphaColor(ThemeColor.KAIROS as string, 1),
                    fontWeight: "bold",
                },
                "a:hover": {
                    color: getAlphaColor(ThemeColor.KAIROS as string, 0.6),
                },
            }}
            flexDirection={"column"}
        >
            {text}
        </Flex>
    );
};