import { useContext, useState } from 'react';
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';
import { Idl, Program } from "@coral-xyz/anchor";
import * as jitoIdl from "../idl/jito_vault_v3.json";
import { AppConfigContext } from '../context';

export const useTokensDeposited = () => {
  const { connection } = useConnection();
  const { solVaultAddress } = useContext(AppConfigContext)

  const [tokensDeposited, setTokensDeposited] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTokensDeposited = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const program = new Program(jitoIdl as Idl, { connection });

      // @ts-ignore
      const data = await program.account.vault.fetch(new PublicKey(solVaultAddress));
      const deposit = data.tokensDeposited / LAMPORTS_PER_SOL;

      setTokensDeposited(deposit.toString());
    } catch (error) {
      setError("Error fetching tokens deposited.")
    } finally {
      setIsLoading(false);
    }
  };

  return { tokensDeposited, isLoading, error, fetchTokensDeposited };
};
