import { Box, Button, Container, Flex, VStack, Text, Input, InputLeftAddon, InputGroup, HStack, Image } from "@chakra-ui/react";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from "@solana/web3.js";
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from "react";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { AppConfigContext } from "../context";
import { useBalance } from "../hooks/useBalance";
import { useEnqueueWithdrawal } from "../hooks/useEnqueueWithdrawal";
import { useWithdrawalTickets } from "../hooks/useWithdrawalTickets";
import { WithdrawalTable } from "../components/WithdrawalTable/WithdrawalTable";
import { getCurrentEpochInfo } from "../helpers/epoch";
import { useBurnWithdrawal } from "../hooks/useBurnWithdrawal";
import { WithdrawalTicket } from "../helpers/dataStructures";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { solAmountFormatter } from "../helpers/formatter";

export const UnstakeJtoPage = () => {
  const { connection } = useConnection();
  const { publicKey, wallet } = useWallet();
  const { jtoVrtMintAddress, jtoMintAddress, jtoVaultPaused, jtoVaultAddress } = useContext(AppConfigContext)

  const kyJto = new PublicKey(jtoVrtMintAddress)

  const { balance, fetchBalance } = useBalance(kyJto);
  const { withdrawalTickets, fetchWithdrawalTickets } = useWithdrawalTickets();
  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();
  const { enqueueWithdrawal, error: enqueueWithdrawalError } = useEnqueueWithdrawal()
  const { burnWithdrawal, error: burnWithdrawalError } = useBurnWithdrawal();

  const [currentEpoch, setCurrentEpoch] = useState(0);
  const [claimingTicketId, setClaimingTicketId] = useState<string | null>(null);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const { setVisible } = useWalletModal();
  const { connecting } = useWallet();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    formik.setValues({
      amount: inputValue,
      receivedAmount: solAmountFormatter.format(Number(inputValue) * 0.998),
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: '',
      receivedAmount: '',
    },
    onSubmit: async (values) => {
      try {
        const signature = await enqueueWithdrawal(jtoVrtMintAddress, jtoVaultAddress, values.amount)
        if (signature) {
          successToast("Your withdrawal request was successful!")
          reload()
        }
      } catch (err) {
        errorToast(`${err}`)
      }
    },
  });

  const burnFormik = useFormik({
    initialValues: {
      claimTicket: null as WithdrawalTicket | null,
    },
    onSubmit: async (values) => {
      if (!values.claimTicket) return;

      setClaimingTicketId(values.claimTicket.base.toString());
      try {
        const signature = await burnWithdrawal(jtoVrtMintAddress, jtoMintAddress, jtoVaultAddress, values.claimTicket)
        if (signature) {
          successToast("Your withdrawal was successful!")
          reload()
        }
      } catch (err) {
        errorToast(`${err}`)
      } finally {
        setClaimingTicketId(null);
      }
    }
  })

  const handleMaxClick = () => {
    formik.setValues({
      amount: balance || "0",
      receivedAmount: balance || "0",
    });
  };

  useEffect(() => {
    if (enqueueWithdrawalError) {
      errorToast(enqueueWithdrawalError)
      formik.setSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueWithdrawalError])

  useEffect(() => {
    if (burnWithdrawalError) {
      errorToast(burnWithdrawalError);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [burnWithdrawalError]);

  useEffect(() => {
    getCurrentEpochInfo(connection).then((epochInfo) => setCurrentEpoch(epochInfo.epoch))
  }, [connection, publicKey])

  useEffect(() => {
    fetchBalance(publicKey)
    fetchWithdrawalTickets(jtoVaultAddress, publicKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, wallet])

  useEffect(() => {
    setFormDisabled(
      formik.values.amount > (balance?.toString() || "0") || formik.values.amount === ""
    );
  }, [formik.values.amount, balance]);

  const handleClaimClick = (ticket: WithdrawalTicket) => {
    burnFormik.setValues({ claimTicket: ticket });
    burnFormik.submitForm();
  };

  const reload = () => {
    formik.resetForm()
    fetchBalance(publicKey);
    fetchWithdrawalTickets(jtoVaultAddress, publicKey);
  };

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW="520px"
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Unstake kyJTO
              </Text>
            </Flex>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              Your kyJTO will be converted to JTO at a 1:1 ratio. You will need to wait at least for one full epoch before being able to claim your JTO.
            </Text>
            
            <Box className="mint-box">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={4}
              >
                <Text fontSize="md" fontWeight="semibold" color="white">
                  (UN)STAKE
                </Text>
                {publicKey && (
                  <HStack spacing={2} alignItems="center">
                    <Image src="/wallet-icon.svg" boxSize="16px" />
                    <Text fontSize="sm" fontWeight="light" color="white">
                      {balance} kyJTO
                    </Text>
                    <Button
                      size="sm"
                      onClick={handleMaxClick}
                      variant="ghost"
                      color="whiteAlpha.800"
                      _hover={{ bg: "whiteAlpha.200" }}
                      backgroundColor="whiteAlpha.300"
                      fontWeight="normal"
                      paddingX={2}
                    >
                      MAX
                    </Button>
                  </HStack>
                )}
              </Flex>

              {/* input restake */}
              <form onSubmit={formik.handleSubmit}>
                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                    // padding: "12px",
                  }}
                >

                  <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/kyjto.svg"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">kyJTO</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  />

                  <Input
                    id="amount"
                    type="number"
                    placeholder="0.0"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    color="white"
                    sx={{
                      width: "100%",
                      height: "60px",
                      fontSize: "md",
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",
                      _placeholder: { color: "#595959" },
                    }}
                  />

                </InputGroup>

                {/* add small down arrow in the middle here */}
                <Flex justifyContent="center" alignItems="center">
                  <ArrowDownIcon color="grey" boxSize={6} />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    TO RECEIVE
                  </Text>
                  <Text fontSize="xs" fontWeight="light" color="#9A9998">
                    No slippage | 0.2% fee
                  </Text>
                </Flex>

                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                    // padding: "12px", // Increase padding for the InputGroup
                  }}
                >
                  <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/jto.svg"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">JTO</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  />
                  <Input
                    id="receive"
                    placeholder="0.0"
                    value={formik.values.receivedAmount}
                    readOnly
                    color="white"
                    sx={{
                      width: "100%",        // Makes the input take up full width within InputGroup
                      height: "60px",       // Set height to match addon
                      fontSize: "md",       // Set font size to match addon
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",     // Aligns the text to the right
                      _placeholder: { color: "595959" },
                    }}
                  />
                </InputGroup>


                {!publicKey ? (
                  <Flex justifyContent="center" width="100%">
                    <Button
                      width="100%"
                      className="mint-box-button"
                      isDisabled={connecting}
                      onClick={() => setVisible(true)}
                    >
                      {connecting ? "Connecting..." : "Connect Wallet"}
                    </Button>
                  </Flex>
                ) : (
                  <Flex justifyContent="center" width="100%">
                    <Button width="100%" className="mint-box-button" 
                    isDisabled={formDisabled || jtoVaultPaused} 
                    isLoading={formik.isSubmitting} type="submit">
                      Enqueue Withdrawal
                    </Button>
                  </Flex>
                )}
              </form>

              <Flex className="exchange-info" mt={4}>
                <Text>Exchange rate:</Text>
                <Text ml={2}>1 JTO = 1 kyJTO</Text>
              </Flex>
            </Box>


              {withdrawalTickets.length > 0 && (
                <WithdrawalTable
                  tickets={withdrawalTickets}
                  currentEpoch={currentEpoch}
                  onClaimClick={handleClaimClick}
                  claimingTicketId={claimingTicketId}
                  currency="kyJTO"
                />
              )}
          </VStack>
        </Container>
      </Flex>
    </>
  );
};
