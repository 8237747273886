import {
  Box,
  Container,
  Flex,
  VStack,
  Text,
  Image,
} from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { Quests, Rank } from "../components";
import "../styles/MintBox.css";
import { useQuests } from "../hooks/useQuests";
import { useRank } from "../hooks/useRank";
import { Claim, Quest, Rank as RankType } from "../rest/kairos/types";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import eventEmitter from '../utils/eventEmitter';

export const VillagePage = () => {
  const { publicKey } = useWallet();
  const { rank, fetchRank } = useRank();
  const { quests, fetchQuests, claimQuest, error, claimedQuests } = useQuests();
  const [mergedRank, setMergedRank] = useState<RankType | null>(null);
  const [mergedClaimedQuests, setMergedClaimedQuests] = useState<Claim[]>([]);
  const [claimingQuestId, setClaimingQuestId] = useState<string | null>(null);
  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();

  const handleClaimClick = (quest: Quest, levelId: number) => {
    setClaimingQuestId(quest.id.toString());
    claimQuest(quest, levelId, publicKey);
  };

  useEffect(() => {
    if (error) {
      setClaimingQuestId(null);
      errorToast(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (claimedQuests) {
      successToast("Victory! Your quest has been fulfilled, and the reward is yours.");
      setClaimingQuestId(null);
      setMergedClaimedQuests(claimedQuests.claims);
    } else if (rank) {
      setMergedClaimedQuests(rank.claims);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimedQuests, rank, publicKey]);

  useEffect(() => {
    if (claimedQuests) {
      setMergedRank(claimedQuests);
    } else if (rank) {
      setMergedRank(rank);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimedQuests, rank]);

  useEffect(() => {
    fetchRank(publicKey);
    fetchQuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    if (claimedQuests) {
      eventEmitter.emit('rankUpdated', claimedQuests);
    }
  }, [claimedQuests]);

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW={{ base: "100%", md: "60%" }}
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={16} align="stretch">
            <Box
              p={4}
              borderRadius="8px"
              backgroundColor="whiteAlpha.100"
            >
              <Flex alignItems="center" gap={4} justifyContent="space-between">
                <VStack align="flex-start" spacing={4}>
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    The Village is an initiative to bring the community together.
                  </Text>
                  <Text fontSize="md" fontWeight="light" color="white">
                    Participate in the quests to help the village grow and to become more important among villagers.
                  </Text>
                </VStack>
                <Image 
                  src="/kyros/village.png" 
                  boxSize="100px"
                  display={{ base: "none", md: "block" }}
                />
              </Flex>
            </Box>
            {mergedRank && <Rank rank={mergedRank} />}
            <Quests quests={quests || []} claimedQuests={mergedClaimedQuests} onClaimClick={handleClaimClick} claimingQuestId={claimingQuestId} />
          </VStack >
        </Container >
      </Flex >
    </>
  );
};
