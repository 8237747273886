import { Text, Progress, Heading } from "@chakra-ui/react";
import { useJitoSOLPrice } from '../../hooks/useJitoSOLPrice';
import { AppConfigContext } from "../../context";
import { useContext } from "react";

interface Props {
    totalSupply: string | null;
    isTotalSupplyLoading: boolean;
    totalSupplyError: string | null;
}

export const TVL = ({ totalSupply, isTotalSupplyLoading, totalSupplyError }: Props) => {
    const { solVaultMaxCap } = useContext(AppConfigContext);
    const { price, isLoading: isPriceLoading, error: priceError } = useJitoSOLPrice();

    const isLoading = isTotalSupplyLoading || isPriceLoading;
    const error = totalSupplyError || priceError;

    const depositedSOL = parseFloat(totalSupply ?? '') || 0;
    const percentage = Math.min((depositedSOL / solVaultMaxCap) * 100, 100)

    // Calculate USDC value
    const usdcValue = depositedSOL * (price ?? 0);

    // Function to format numbers in K and M
    const formatNumber = (number: number) => {
        const absNumber = Math.abs(number);
        if (absNumber >= 1e6) {
            return (number / 1e6).toFixed(2).replace(/\.00$/, '') + 'M';
        } else if (absNumber >= 1e3) {
            return (number / 1e3).toFixed(2).replace(/\.00$/, '') + 'K';
        } else {
            return number.toFixed(2);
        }
    };

    return (
        <div>
            <Heading as="h2" fontSize="xl" color="white" fontWeight="bold" mb={4}>
                TVL
            </Heading>
            {isLoading ? (
                <Text color="white">Loading...</Text>
            ) : error ? (
                <Text color="red.500">{error}</Text>
            ) : (
                <>
                    <Text fontSize="xl" color="white" fontWeight="light">
                        {formatNumber(depositedSOL)} JitoSOL {priceError ? null : <> ≈ <Text as="span" fontWeight="semibold">${formatNumber(usdcValue)}</Text></>}
                    </Text>
                    <Progress
                        value={(depositedSOL / solVaultMaxCap) * 100}
                        colorScheme="gray"
                        size="lg"
                        mt={4}
                        borderRadius="md"
                        sx={{
                            '& > div': {
                                backgroundColor: '#5EF4F3'
                            }
                        }}
                    />
                    {percentage > 99.999 ? (
                        <Text fontSize="sm" color="gray.500" mt={2}>
                            Cap is filled 🎉
                        </Text>
                    ) : (
                        <Text fontSize="sm" color="gray.500" mt={2}>
                            {percentage.toFixed(2)}% of (re)staking limit reached
                        </Text>
                    )}
                </>
            )}
        </div>
    );
};
