export interface AppConfig {
    // General
    environment: string;
    rpcUrl: string;
    priorityFeeLevel: string;

    // Jito (Re)staking
    vaultProgramAddress: string;
    vaultConfigAddress: string;
    kyrosFeeWalletAddress: string;
    jitoFeeWalletAddress: string;

    // kySOL Vault
    jitoSolMintAddress: string;
    supportedTokenMintAddress: string;
    solVrtMintAddress: string;
    solVaultAddress: string;
    solVaultMaxCap: number;
    solVaultPaused: boolean;

    // kyJTO Vault
    jtoMintAddress: string;
    jtoVrtMintAddress: string;
    jtoVaultAddress: string;
    jtoVaultPaused: boolean;

    // Analytics
    mixpanelToken: string;
    mixpanelDebug: boolean;

    // API
    apiUrl: string;
    pointsApiUrl: string;
}

export const appConfig: AppConfig = {
    // General      
    environment: process.env.REACT_APP_ENVIRONMENT || '',
    rpcUrl: process.env.REACT_APP_RPC_URL || '',
    priorityFeeLevel: process.env.REACT_APP_PRIORITY_FEE_LEVEL || '',

    // Jito (Re)staking
    vaultProgramAddress: process.env.REACT_APP_VAULT_PROGRAM_ADDRESS || '',
    vaultConfigAddress: process.env.REACT_APP_VAULT_CONFIG_ADDRESS || '',
    kyrosFeeWalletAddress: process.env.REACT_APP_KYROS_WALLET_ADDRESS || '',
    jitoFeeWalletAddress: process.env.REACT_APP_JITO_WALLET_ADDRESS || '',

    // kySOL Vault
    jitoSolMintAddress: process.env.REACT_APP_JITO_SOL_MINT_ADDRESS || '',
    supportedTokenMintAddress: process.env.REACT_APP_SUPPORT_TOKEN_MINT_ADDRESS || '',
    solVrtMintAddress: process.env.REACT_APP_SOL_VRT_MINT_ADDRESS || '',
    solVaultAddress: process.env.REACT_APP_SOL_VAULT_ADDRESS || '',
    solVaultMaxCap: Number(process.env.REACT_APP_SOL_VAULT_MAX_CAP) || 0,
    solVaultPaused: process.env.REACT_APP_SOL_VAULT_PAUSED ? process.env.REACT_APP_SOL_VAULT_PAUSED === 'true' : true,

    // kyJTO Vault
    jtoMintAddress: process.env.REACT_APP_JTO_MINT_ADDRESS || '',
    jtoVrtMintAddress: process.env.REACT_APP_JTO_VRT_MINT_ADDRESS || '',
    jtoVaultAddress: process.env.REACT_APP_JTO_VAULT_ADDRESS || '',
    jtoVaultPaused: process.env.REACT_APP_JTO_VAULT_PAUSED ? process.env.REACT_APP_JTO_VAULT_PAUSED === 'true' : true,

    // Analytics
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || '',
    mixpanelDebug: process.env.REACT_APP_MIXPANEL_DEBUG ? process.env.REACT_APP_MIXPANEL_DEBUG === 'true' : false,

    // API
    apiUrl: process.env.REACT_APP_API_URL || '',
    pointsApiUrl: process.env.REACT_APP_POINTS_API_URL || '',
};
