import {
    Flex,
    HStack,
    Image,
    IconButton,
    useDisclosure,
    VStack,
    useBreakpointValue,
    Slide,
    Box,
    MenuButton,
    MenuItem,
    Menu,
    MenuList,
    Text,
    Badge
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Suspense, useEffect } from 'react';
import '../../styles/Header.css';
import '../../styles/WalletConnectButton.css';
import '../../styles/WalletConnectModal.css';
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useWallet } from "@solana/wallet-adapter-react";
import { RankBox, Link, CustomWalletMultiButton } from "../../components";
import { ThemeColor } from "../../theme/palette";

export const Header = () => {
    const logoSrc = useBreakpointValue({
        base: "/logo-mobile.png",
        md: "/logo.png",
    });

    const logoHeight = useBreakpointValue({
        base: '16px',
        md: '20px',
    });

    const location = useLocation(); // Get current route
    const { isOpen, onClose, onToggle } = useDisclosure(); // Manage the slide state

    // Prevent background scrolling when slide is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isOpen]);

    const { publicKey } = useWallet();

    return (
        <>
            <Flex
                as="header"
                className="header-nav"
                h="8.8rem"
                w="100%"
                px={{ base: "3rem", md: "5rem", lg: "8rem" }}
                alignItems="center"
                justifyContent="space-between"
                zIndex="11" // Ensure header is above overlay and slide
                onClick={isOpen ? onClose : undefined} // Close slide when header is clicked
            >
                <HStack spacing={{ base: 4, sm: 4, md: 4, lg: 8 }}>
                    {/* Hamburger menu button, displayed only on mobile */}
                    <IconButton
                        aria-label="Open menu"
                        icon={<HamburgerIcon color="white" w={6} h={6} />}
                        display={{ base: 'block', lg: 'none' }}
                        onClick={onToggle} // Use onToggle to toggle the slide
                        variant="unstyled"
                    />

                    {/* Logo */}
                    <Link to="/">
                        <Image src={logoSrc} alt="Kyros" height={logoHeight} />
                    </Link>

                    {/* Nav links, hidden on mobile */}
                    <HStack spacing={{ base: 4, sm: 4, md: 4, lg: 8 }} display={{ base: 'none', lg: 'flex' }}>
                        <Menu>
                            <MenuButton
                                className={(location.pathname === "/" || location.pathname === "/jto") ? "active nav-link" : "nav-link"}
                                color="white"
                                _hover={{ color: ThemeColor.KAIROS }}
                            >
                                (Re)stake <ChevronDownIcon />
                            </MenuButton>

                            <Badge
                                ml={-5}
                                colorScheme="green"
                                display={{ base: 'none', lg: 'inline-block' }} // Show only on larger screens
                            >
                                New
                            </Badge>
                            <MenuList>
                                <MenuItem as='a' href='/'>
                                    <HStack>
                                        <Image src="/kysol.png" alt="kySOL" width="20px" height="20px" />
                                        <Text>Solana (JitoSOL)</Text>
                                    </HStack>
                                </MenuItem>
                                <MenuItem as='a' href='/jto'>
                                    <HStack>
                                        <Image src="/kyjto.png" alt="kyJTO" width="20px" height="20px" />
                                        <Text>Jito (JTO)</Text>
                                    </HStack>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        <Menu>
                            <MenuButton
                                className={(location.pathname === "/redeem/sol" || location.pathname === "/redeem/jto") ? "active nav-link" : "nav-link"}
                                color="white"
                                _hover={{ color: ThemeColor.KAIROS }}
                            >
                                (Un)stake <ChevronDownIcon />
                            </MenuButton>
                            <MenuList>
                                <MenuItem as='a' href='/redeem/sol'>
                                    <HStack>
                                        <Image src="/kysol.png" alt="kySOL" width="20px" height="20px" />
                                        <Text>Solana (JitoSOL)</Text>
                                    </HStack>
                                </MenuItem>
                                <MenuItem as='a' href='/redeem/jto'>
                                    <HStack>
                                        <Image src="/kyjto.png" alt="kyJTO" width="20px" height="20px" />
                                        <Text>Jito (JTO)</Text>
                                    </HStack>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        <Link
                            to="/defi"
                            className={location.pathname === "/defi" ? "active nav-link" : "nav-link"}
                        >
                            DeFi
                        </Link>
                        <Link
                            to="/village"
                            className={location.pathname === "/village" ? "active nav-link" : "nav-link"}
                        >
                            The Village
                        </Link>
                    </HStack>
                </HStack>

                <HStack spacing={4}>
                    {publicKey && <RankBox />}
                    <Suspense>
                        <CustomWalletMultiButton />
                    </Suspense>
                </HStack>
            </Flex>

            {/* Overlay to prevent background interaction and close slide when clicked */}
            {isOpen && (
                <Box
                    position="fixed"
                    top="8.8rem" // Start from below the header
                    left="0"
                    width="100vw"
                    height="calc(100vh - 8.8rem)"
                    bg="rgba(0, 0, 0, 0.5)" // Semi-transparent background
                    zIndex="9"
                    onClick={onClose}
                />
            )}

            {/* Side menu using Slide */}
            <Slide direction="left" in={isOpen} style={{ zIndex: 10, position: 'fixed', top: '8.8rem' }}>
                <Box
                    bg="rgba(0, 0, 0, 0.5)" // Lighter glass effect
                    backdropFilter="blur(10px)" // Apply blur for glass effect
                    w="full"
                    h="calc(100vh - 8.8rem)" // Adjust for header height
                    position="fixed"
                    overflowY="auto"
                >
                    <VStack align="start" spacing={4} mt={4} pl={4}>
                        <Link
                            to="/"
                            width="90%"
                            onClick={onClose}
                            className={`menu-button ${location.pathname === "/" ? "active" : ""}`}
                        >
                            (Re)stake SOL
                        </Link>
                        <Link
                            to="/jto"
                            width="90%"
                            onClick={onClose}
                            className={`menu-button ${location.pathname === "/jto" ? "active" : ""}`}
                        >
                            (Re)stake JTO
                        </Link>
                        <Link
                            to="/redeem/sol"
                            width="90%"
                            onClick={onClose}
                            className={`menu-button ${location.pathname === "/redeem/sol" ? "active" : ""}`}
                        >
                            (Un)stake SOL
                        </Link>
                        <Link
                            to="/redeem/jto"
                            width="90%"
                            onClick={onClose}
                            className={`menu-button ${location.pathname === "/redeem/jto" ? "active" : ""}`}
                        >
                            (Un)stake JTO
                        </Link>
                        <Link
                            to="/defi"
                            width="90%"
                            onClick={onClose}
                            className={`menu-button ${location.pathname === "/defi" ? "active" : ""}`}
                        >
                            DeFi
                        </Link>
                        <Link
                            to="/village"
                            width="90%"
                            onClick={onClose}
                            className={`menu-button ${location.pathname === "/village" ? "active" : ""}`}
                        >
                            The Village
                        </Link>
                    </VStack>
                </Box>
            </Slide>
        </>
    );
};
