import { Box, Flex, Text, Image, Progress, HStack, Badge, Tooltip, VStack } from "@chakra-ui/react";
import { Rank as RankType, XpType } from "../../rest/kairos/types";
import { InfoIcon } from "@chakra-ui/icons";
import { getColorForXpType, getProgress } from "../../helpers/xp";
import { useLeaderboard } from "../../hooks/useLeaderboard";
import { useEffect } from "react";

interface Props {
    rank: RankType;
}

export const Rank = ({ rank }: Props) => {
    const { leaderboard, fetchLeaderboard } = useLeaderboard();
    
    const getRequirementText = (type: XpType) => {
        if (rank.rank.requirements.find(req => req.type === type)) {
            return `${type}: ${rank[type]} / ${rank.rank.requirements.find(req => req.type === type)?.amount}`;
        }

        if (rank[type] === 0) {
            return `${type}: 0`;
        }

        return `${type}: +${rank[type]}`;
    };

    const getTopPercentage = () => {
        if (!leaderboard || !leaderboard.xp) return 0;
        const totalUsers = Object.entries(leaderboard.xp).reduce((sum, [xp, count]) => sum + count, 0);
        const usersAbove = Object.entries(leaderboard.xp)
            .filter(([xp]) => Number(xp) >= rank.xp)
            .reduce((sum, [_, count]) => sum + count, 0);
        const usersInXpLevel = Object.entries(leaderboard.xp)
            .filter(([xp]) => Number(xp) === rank.xp)
            .reduce((sum, [_, count]) => sum + count, 0);
        return Math.max(1, Math.round(((usersAbove - usersInXpLevel) / totalUsers) * 100));
    }

    useEffect(() => {
        fetchLeaderboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box bg="#18181b" p={8} className="mint-box" maxW="100%">
            <Flex alignItems="center" gap={8}>
                <Box flexShrink={0}>
                    <Image
                        src={`/kyros/${rank.rank.title.toLowerCase()}.png`}
                        boxSize="50px"
                        alt={rank.rank.title}
                    />
                </Box>
                <Box>
                    <Flex alignItems="center" gap={2}>
                        <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            color="white"
                        >
                            {rank.rank.title}
                        </Text>
                    </Flex>
                    <Text
                        fontSize="md"
                        color="whiteAlpha.800"
                    >
                        {rank.rank.description}
                    </Text>
                </Box>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
                <Box width="100%">
                    <Text fontSize="lg" fontWeight="semibold" color="white" textAlign="left" mb={4}>
                        XP: {rank.xp} / {rank.rank.maxXp}
                    </Text>
                    <Progress
                        value={getProgress(rank.xp, rank.rank.minXp, rank.rank.maxXp)}
                        size="lg"
                        width="100%"
                        height="5px"
                        colorScheme="gray"
                        sx={{
                            '& > div': {
                                backgroundColor: '#5EF4F3'
                            },
                            backgroundColor: '#2D2D2D'
                        }}
                        mb={4}
                    />
                    <HStack spacing={4} mb={4}>
                        <Badge colorScheme={getColorForXpType(XpType.Attack)}>{getRequirementText(XpType.Attack)}</Badge>
                        <Badge colorScheme={getColorForXpType(XpType.Defense)}>{getRequirementText(XpType.Defense)}</Badge>
                        <Badge colorScheme={getColorForXpType(XpType.Loyalty)}>{getRequirementText(XpType.Loyalty)}</Badge>
                        <Tooltip hasArrow={false} label='Level up your skills, this will help you become a better warrior.' bg='whiteAlpha.300' color='white'>
                            <InfoIcon />
                        </Tooltip>
                    </HStack>
                </Box>
            </Flex>
            <Flex justifyContent="space-between" mb={6} mt={8}>
                <Text
                    fontWeight="semibold"
                    fontSize="large"
                    color="white"
                    lineHeight="1.2"
                    mr={2}
                >
                    The Village 🏘️
                </Text>
            </Flex>
            {leaderboard && leaderboard.ranks.length > 0 && (
                <HStack justifyContent="flex-start">
                    <Text fontSize="md" color="whiteAlpha.700">You are in the</Text>
                    <Badge colorScheme="green">Top {getTopPercentage()}%</Badge>
                </HStack>
            )}  

            {leaderboard && leaderboard.ranks.length > 0 && (
                <>
                    <Flex justifyContent="space-between" mt={4}>
                        <VStack align="flex-start" spacing={4}>
                            <HStack spacing={6} wrap="wrap">
                                {leaderboard.ranks.map((step) => (
                                    <Box
                                        key={step.rank.title}
                                        display="flex"
                                        alignItems="center"
                                        gap={3}
                                        p={3}
                                        borderRadius="md"
                                        backgroundColor="whiteAlpha.100"
                                    >
                                        <Image
                                            src={`/kyros/${step.rank.image.toLowerCase()}`}
                                            alt={step.rank.title}
                                            boxSize="40px"
                                            objectFit="cover"
                                            borderRadius="full"
                                        />
                                        <VStack spacing={0} align="flex-start">
                                            <Text fontSize="sm" color="white">
                                                {step.rank.title}
                                            </Text>
                                            <Text fontSize="xs" color="whiteAlpha.700">
                                                {step.count}
                                            </Text>
                                        </VStack>
                                    </Box>
                                ))}
                            </HStack>
                        </VStack>
                    </Flex>
                </>
            )}
        </Box>
    );
}