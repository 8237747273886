import { useState, useEffect } from 'react';

export const useJtoPrice = () => {
    const [price, setPrice] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchPrice = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const pythResponse = await fetch(
                'https://hermes.pyth.network/v2/updates/price/latest?ids%5B%5D=0xb43660a5f790c69354b0729a5ef9d50d68f1df92107540210b9cccba1f947cc2'
            );
            const pythData = await pythResponse.json();
            const pythPriceData = pythData?.parsed?.[0]?.price?.price;
            const expo = pythData.parsed[0].price.expo;
            const pythPrice = pythPriceData ? pythPriceData * Math.pow(10, expo) : null;
            if (pythPrice) {
                setPrice(pythPrice);
            } else {
                throw new Error('Pyth price data not found');
            }
        } catch (err) {
            console.error('Error fetching JTO price from Pyth:', err);
            setError('Error fetching JTO Price');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPrice();
    }, []);

    return { price, isLoading, error };
};
